import Layout from "../../common/components/Layout"
import Header from '../../common/components/Header'
import Footer from '../../common/components/Footer'
import styled from 'styled-components'
import building from '../../common/assets/background/building.jpg'
import perkebunan from '../../common/assets/background/perkebunan.jpg'
import vanillaPaste from '../../common/assets/product/vanilla-paste.png'
import vanillaTahiti from '../../common/assets/product/tahiti.jpg'
import vanillaExtract from '../../common/assets/product/vanilla-extract.jpg'
import vanillaPlanifolia from '../../common/assets/product/planifolia.jpg'
import koi from '../../common/assets/partners/KOI.jpeg'
import honeysHealthyHive from '../../common/assets/partners/honeys-healthy-hive.jpeg'
import nigitaDessertBar from '../../common/assets/partners/nigita-dessert-bar.jpeg'
import dailyBaguette from '../../common/assets/partners/daily-baguette.jpeg'
import gustoGelatoCaffe from '../../common/assets/partners/gusto-gelato-caffe.jpeg'
import ilvero from '../../common/assets/partners/ilvero.jpeg'
import monsieurSpoon from '../../common/assets/partners/monsieur-spoon.jpeg'
import albaRistorante from '../../common/assets/partners/alba-ristorante.jpeg'
import donBakeshop from '../../common/assets/partners/don-bakeshop.jpeg'
import kanvazPatisserie from '../../common/assets/partners/kanvaz-patisserie.jpg'
import laRivieraRistorante from '../../common/assets/partners/la-riviera-ristorante.jpeg'
import vivoliGelato from '../../common/assets/partners/vivoli-gelato.jpg'
import sevenD from '../../common/assets/partners/seven-d.jpeg'
import products from '../../common/assets/background/product.jpeg'
import { useState, useEffect } from 'react'
import themes from "../../common/themes"

const Wrapper = styled.div`
  width: 100%;
  margin-top: -80px;
`

const Container = styled.div`
  min-height: ${({minHeight}) => minHeight || '100vh'};
  width: 100%;
  background-image: linear-gradient(transparent 90%, white 100%), url(${({backgroundImage}) => backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;

  .position-left {
    > h1,h2 {
      text-align: left;
      width: 25%;
      position: absolute;
      left: 5%;
    }

    @media screen and (max-width: 768px) {
      background-position-x: 60%;
      > h1,h2 {
        text-align: center;
        width: 100%;
        left: 0;
      }

      > div > a {
        top: 90%;
        animation: animateButtonPosition 2s ease;

        @keyframes animateButtonPosition {
          from { top: 93% };
          to { top: 90% };
        }
      }
    }
  }
`

const ProductHeadline = styled.h1`
  // right: ${({right}) => right};
  top: 50%;
  position: absolute;
  color: white;
  animation: animateHeadline 2s ease;
  width: 100%;
  text-align: center;
  
  @keyframes animateHeadline {
    from { top: 53% };
    to { top: 50 };
  }

  @keyframes leftToRight {
    0% { opacity: 0 }
    50% { right: 100%; opacity: 0 }
    90% { opacity: 1 }
    100% { right: ${({right}) => right} }
  }
  
  @media screen and (max-width: 768px) {
    right: 0;
    top: 50%;
    text-align: center;
    width: 100%;

    @keyframes rightToLeft {
      from { right: -100% };
      to { right: 0 };
    }
  }
`

const ProductDescription = styled.h2`
  // right: ${({right}) => right};
  top: 44%;
  position: absolute;
  color: white;
  width: 100%;
  text-align: center;
  animation: animateDescription 2s ease;
  
  @keyframes animateDescription {
    from { top: 41% };
    to { top: 44% };
  }

  @keyframes leftToRight {
    0% { opacity: 0 }
    50% { right: 100%; opacity: 0 }
    90% { opacity: 1 }
    100% { right: 60% }
  }
  
  @media screen and (max-width: 768px) {
    right: 0;
    top: 44%;
    text-align: center;
    width: 100%;

    @keyframes rightToLeft {
      from { right: -100% };
      to { right: 0 };
    }
  }
`

const Button = styled.a`
  color: white;
  padding: 15px 25px;
  background-color: ${themes.colors.primary};
  text-decoration: none;
  border-radius: 5px;
`

const SectionButton = styled(Button)`
  top: 70%;
  // right: 32%;
  position: absolute;
  box-shadow: 3px 2px 2px rgba(255, 255, 255, 0.6);
  animation: animateButton 2s ease;

  @keyframes animateButton {
    from { top: 73% };
    to { top: 70% };
  }

  @media screen and (max-width: 768px) {
    // right: 40%;
    top: 80%;
    animation: animateButtonResponsive 2s ease;

    @keyframes animateButtonResponsive {
      from { top: 83% };
      to { top: 80% };
    }
  }

  // @media screen and (max-width: 425px) {
  //   // right: 20%;
  //   top: 80%;
  // }
`

const Slider = styled.div`
  display: flex;

  > div {
    position: absolute;
    left: 0;
    width: 100%;
  }

  .active {
    animation: actives 2s linear;
    opacity: 1;
    transition-delay: 3s;
  }

  .inactive {
    animation: inactives 2s linear;
    opacity: 0;
    transition-delay: 5s;
    display: none;
  }

  @keyframes actives {
    from { opacity: 0 }
    to { opacity: 1 }
  }

  @keyframes inactives {
    from { opacity: 1 }
    to { opacity: 0 }
  }
`

const ProductSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  &:nth-child(even) {
    > div {
      >div:nth-child(1) {
        display: none;
      }
      >div:nth-child(3) {
        display: flex;
      }

      @media screen and (max-width: 768px) {
        >div:nth-child(3) {
          display: none;
        }
        >div:nth-child(1) {
          display: block;
        }
      }
    }
  }
`

const ProductContainer = styled.div`  
  max-width: 1140px;
  display: flex;
  position: relative;
  width: 100%;
  padding-top: 10rem;

  > div {
    width: 100%;
    position: relative;
  }

  @media screen and (max-width: 768px) {
    display: block;

    .is-not-mobile {
      display: none;
    }
  }
`

const ProductImage = styled.div`
  display: flex;
  justify-content: center;
  width: 150%;
  position: relative;

  @media screen and (max-width: 768px) {
    width: 100%;

    > img {
      width: 100%;
    }
  }
`

const Section = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10rem;
`

const ContactContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(76, 175, 80, .7);
  border-radius: 10px;
  padding: 25px;
  display: flex;

  @media screen and (max-width: 768px) {
    display: block;
  }
`

const ContactContain = styled.div`
  width: 50%;
  color: white;

  > p {
    text-align: justify;
  }

  > form {
    padding: 0px 10%;

    > div > label {
      color: whtie;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;

    > form {
      padding: 0px;
    }
  }
`

const ClientContainer = styled.div`
  width: 100%;
  max-width: 1140px;
  padding-bottom: 8rem;

  @media screen and (max-width: 768px) {
    padding-bottom: 10rem;
  }
`

const ClientImages = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;

  @media screen and (max-width: 768px) {
    display: block;
    padding-bottom: 0;
  }
`

const ClientImage = styled.div`
  display: flex;
  align-items: center;
  width: 313px;
  justify-content: center;
  box-shadow: 0 30px 90px 0 rgba(117, 117, 117, 0.15);
  margin: 0 15px;

  > img {
    width: 313px;
    height: 110px;
    object-fit: scale-down;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0 auto;

    > img {
      width: 100%;
      height: auto;
    }
  }
`

function Home() {
  const [index, setIndex] = useState(0)
  const [data, setData] = useState({
    name: '',
    email: '',
    message: ''
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (index === 2) {
        setIndex(0)
        if(document.getElementById('slide-0') !== null) {
          document.getElementById('slide-0').style.display = 'block'
          setTimeout(() => {
            document.getElementById('slide-2').style.display = 'none'
          }, 2500)
        }
      }
      else {
        setIndex(index + 1)
        if(document.getElementById(`slide-${index}`) !== null) {
          document.getElementById(`slide-${index + 1}`).style.display = 'block'
          setTimeout(() => {
            document.getElementById(`slide-${index}`) !== null ? document.getElementById(`slide-${index}`).style.display = 'none' : console.log('')
          }, 2500)
        }
      }
    }, 7000)
  }, [index])

  const handleSubmit = (e) => {
    e.preventDefault()
    const message = `${data.message}%0A%0ABest Regard,%0A${data.name}%0A${data.email}`
    window.location.href = `mailto:info@allnaturalindonesia.com?subject=Hi All Natural Indonesia&body=${message}`
  }

  return (
    <Layout header={{ component: <Header /> }} footer={{ component: <Footer /> }}>
      <Wrapper>
        <Container>
          <Slider>
            <Container style={{ backgroundImage: `linear-gradient(rgba(0,0,0,0.5) 90%, rgba(0, 0, 0, 0.5) 100%), url(${building})`}} className={`position-left ${index === 0 ? 'active' : 'inactive'}`} id="slide-0">
              <ProductDescription animation="rightToLeft" right="10%">Welcome to our company</ProductDescription>
              <ProductHeadline animation="rightToLeft" right="10%">
                All Natural Indonesia is one of the leading producer of 
                vanilla bean product in Indonesia
              </ProductHeadline>
              <Section>
                <SectionButton href="about-us">Company Profile</SectionButton>
              </Section>
            </Container>
            <Container style={{ backgroundImage: `linear-gradient(rgba(0,0,0,0.5) 90%, rgba(0, 0, 0, 0.5) 100%), url(${products})`}} className={index === 1 ? 'active' : 'inactive'} id="slide-1">
              <ProductDescription animation="rightToLeft" right="10%">Product</ProductDescription>
              <ProductHeadline animation="rightToLeft" right="10%">Specialized in Vanilla Bean</ProductHeadline>
              <Section>
                <SectionButton href="#product">Explore Our Product</SectionButton>
              </Section>
            </Container>
            <Container style={{ backgroundImage: `linear-gradient(rgba(0,0,0,0.5) 90%, rgba(0, 0, 0, 0.5) 100%), url(${perkebunan})`}} className={index === 2 ? 'active' : 'inactive'} id="slide-2">
              <ProductDescription animation="rightToLeft" right="10%">Supplying across the globe</ProductDescription>
              <ProductHeadline animation="rightToLeft" right="10%">The leading supplier of vanilla products</ProductHeadline>
              <Section>
                <SectionButton href="contact-us">Contact Us</SectionButton>
              </Section>
            </Container>
          </Slider>
        </Container>
        <Container id="product" minHeight="120vh">
          <ProductSection>
            <ProductContainer>
              <ProductImage>
                <img src={vanillaTahiti} alt="vainlla tahiti" />
              </ProductImage>
              <div>
                <h2>Vanilla Tahiti</h2>
                <p>Tahiti vanilla is a different experience entirely. It is extremely complex 
                  and is used in Tahitian cooking for not only sweet confections but also savory 
                  dishes. Tahitian vanilla extract is luxurious, intensely aromatic, exotic and 
                  even sensual. The flavor profile has notes of sweetness, cherry, and spice
                </p>
                <div style={{ marginTop: '50px' }}>
                  <Button href="/product">See Our Product</Button>
                </div>
              </div>
            </ProductContainer>
          </ProductSection>
          <ProductSection>
            <ProductContainer>
              <ProductImage>
                <img src={vanillaPlanifolia} alt="vanilla planifolia" />
              </ProductImage>
              <div>
                <div style={{ width: '100%' }}>
                  <h2 style={{ width: '100%' }}>Vanilla Planifolia</h2>
                  <p style={{ width: '100%' }}>Indonesia Vanilla planifolia is a species of vanilla orchid. It is native to Mexico, the Caribbean, northern South America, and Central America, and is one of the primary sources for vanilla flavouring, due to its high vanillin content.</p>
                  <div style={{ marginTop: '50px' }}>
                    <Button href="/product">See Our Product</Button>
                  </div>
                </div>
              </div>
              <ProductImage>
                <img src={vanillaPlanifolia} alt="vanilla planifolia" />
              </ProductImage>
            </ProductContainer>
          </ProductSection>
          <ProductSection>
            <ProductContainer>
              <ProductImage>
                <img src={vanillaPaste} alt="vanilla paste" />
              </ProductImage>
              <div>
                <h2>Vanilla Paste</h2>
                <p>Vanilla bean paste is a rich, thick paste that contains a blend of the 
                  scraped-out vanilla pod seeds and vanilla extract. It is a great option if 
                  you can’t find vanilla bean pods or you feel like the pods are too much work. 
                  You still get the classic, rich vanilla flavor that the pod offers . It is 
                  not always easy to find vanilla bean paste, but if a recipe calls for vanilla 
                  and you can’t find the pods or extract for any reason, vanilla bean paste is 
                  a great vanilla extract substitute (especially for vanilla frosting, custard 
                  or ice cream).
                </p>
                <div style={{ marginTop: '50px' }}>
                  <Button href="/product">See Our Product</Button>
                </div>
              </div>
            </ProductContainer>
          </ProductSection>
          <ProductSection>
            <ProductContainer>
              <ProductImage>
                <img src={vanillaExtract} alt="vanilla extract" />
              </ProductImage>
              <div>
                <h2>Natural Vanilla Extract</h2>
                <p>
                  The extract is made by soaking vanilla beans in water and an alcohol-based solution where 
                  it ages for several months, during which time the vanilla flavor is extracted from the bean. 
                  Vanilla extract or single strength vanilla extract is a stronger, liquid form of vanilla 
                  flavoring and is often used to flavor brownies, cookies and a variety of cakes.  Extracts can 
                  be stored indefinitely without losing much flavor. Keep the extract in a cool dry area away 
                  from heat and direct light in an air tight sealed container.
                </p>
                <div style={{ marginTop: '50px' }}>
                  <Button href="/product">See Our Product</Button>
                </div>
              </div>
              <ProductImage>
                <img src={vanillaExtract} alt="vanilla extract" />
              </ProductImage>
            </ProductContainer>
          </ProductSection>
          {/* <ProductSection>
            <ProductContainer style={{ paddingTop: '0' }}>
              <div>
                <h2 style={{ textAlign: 'center' }}>Vanilla Powder</h2>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img src={vanillaPowder} style={{ width: '30%', borderRadius: '50%' }} />
                </div>
              </div>
            </ProductContainer>
          </ProductSection> */}
        </Container>
        <Container minHeight="50vh" backgroundImage="black">
          <Section>
            <ContactContainer>
              <ContactContain>
                <h1>Connect With us</h1>
                <p>All Natural Indonesia is one of the leading producer of vanilla bean  product in Indonesia. We have been established for over 2 years in derivative products. We are producing vanilla bean ( Tahiti and Planifolia ), vanilla paste, and vanilla extract.</p>
                <p>Our company also continues to collaborate with several other companies abroad to help distribute goods to be much better. At present our company has cooperated with more than 10 companies in 5 countries.</p>
                <a href="/about-us" style={{ textDecoration: 'none' }}>Read more...</a>
              </ContactContain>
              <ContactContain>
                <form id="contactUs" onSubmit={handleSubmit}>
                  <div style={{marginTop: '1rem'}}>
                    <label for="name">Name*</label>
                    <input id="name" name="name" type="text" style={{ width: '100%', borderRadius: '5px', height: '25px' }} onChange={e => setData({ ...data, name: e.target.value})} />
                  </div>
                  <div style={{marginTop: '1rem'}}>
                    <label for="email">Email*</label>
                    <input id="email" name="email" type="email" style={{ width: '100%', borderRadius: '5px', height: '25px' }} onChange={e => setData({ ...data, email: e.target.value})} />
                  </div>
                  <div style={{marginTop: '1rem'}}>
                    <label for="message">Message*</label>
                    <textarea id="message" name="message" form="contactUs" style={{ width: '100%', borderRadius: '5px', resize: 'none' }} rows="10" onChange={e => setData({ ...data, message: e.target.value})} />
                  </div>
                  <button type="submit" style={{ color: themes.colors.white, backgroundColor: themes.colors.primary, padding: '10px 50px', marginTop: '5%', cursor: 'pointer' }}>Submit</button>
                </form>
              </ContactContain>
            </ContactContainer>
          </Section>
        </Container>
        <Container minHeight="25vh">
          <Section>
            <ClientContainer>
              <h1 style={{ textAlign: 'center' }}>our client</h1>
              <ClientImages>
                <ClientImage>
                  <img src={honeysHealthyHive} alt="honey healthy hive" />
                </ClientImage>
                <ClientImage>
                  <img src={koi} alt="KOI" />
                </ClientImage>
                <ClientImage>
                  <img src={nigitaDessertBar} alt="nigita dessert bar" />
                </ClientImage>
              </ClientImages>
              <ClientImages>
                <ClientImage>
                  <img src={dailyBaguette} alt="daily baguette" />
                </ClientImage>
                <ClientImage>
                  <img src={gustoGelatoCaffe} alt="gusto gelato caffe" />
                </ClientImage>
                <ClientImage>
                  <img src={ilvero} alt="ilvero" />
                </ClientImage>
              </ClientImages>
              <ClientImages>
                <ClientImage>
                  <img src={monsieurSpoon} alt="monsieur spoon" />
                </ClientImage>
                <ClientImage>
                  <img src={albaRistorante} alt="Alba Ristorante" />
                </ClientImage>
                <ClientImage>
                  <img src={laRivieraRistorante} alt="La Riviera Ristorante" />
                </ClientImage>
              </ClientImages>
              <ClientImages>
                <ClientImage>
                  <img src={donBakeshop} alt="Don Bakeshop" />
                </ClientImage>
                <ClientImage>
                  <img src={vivoliGelato} alt="Vivoli Gelato" />
                </ClientImage>
                <ClientImage>
                  <img src={kanvazPatisserie} alt="Kanvaz Patisserie" />
                </ClientImage>
              </ClientImages>
              <ClientImages>
                <ClientImage>
                  <img src={sevenD} alt={`Seven Days Kitchen & Bar`} />
                </ClientImage>
              </ClientImages>
            </ClientContainer>
          </Section>
        </Container>
      </Wrapper>
    </Layout>
  );
}

export default Home
